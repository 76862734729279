import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface DevelopmentsFilterState {
  name: string
  constructionStatus: string
  location: string
  services: string
}

const initialState: DevelopmentsFilterState = {
  name: '',
  constructionStatus: '',
  location: '',
  services: '',
}

const developmentsFilterSlice = createSlice({
  name: 'developments',
  initialState,
  reducers: {
    setConstructionStatusFilter: (state, action: PayloadAction<string>) => {
      state.constructionStatus = action.payload
    },

    setLocationFilter: (state, action: PayloadAction<string>) => {
      state.location = action.payload
    },

    setServicesFilter: (state, action: PayloadAction<string>) => {
      state.services = action.payload
    },

    clearFilters: (state) => {
      state.constructionStatus = ''
      state.location = ''
    },
  },
})

export const { setConstructionStatusFilter, setLocationFilter, setServicesFilter, clearFilters } =
  developmentsFilterSlice.actions

export default developmentsFilterSlice.reducer
